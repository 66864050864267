import { useSnackbar } from "notistack";
import { useState, FormEvent } from "react";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import { extractInputs } from "assets/data/applicationsTypes";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import {
	InputOnChange,
	InputDataProps,
	InputOnChangeFormItem,
} from "utils/types";
import { getValueFromArray, addedErrorOnField } from "utils/helpers";
import { Button, InputsSpreader } from "components/FormElements";
import {
	Container,
	CustomForm,
	ExtractContent,
	FormControlItem,
	FormsWrapper,
	RemoveFormButtom,
} from "./styles";
import { ButtonContainer } from "components/Form/styles";
import { TrashcanIcon } from "assets/icons";

export const CreationExtract = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [inputProps, setInputProps] = useState<
		{ id: number; form: InputDataProps[] }[]
	>([{ id: 1, form: extractInputs }]);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const request = useRequest;
	const url = `${baseApiUrl}/applications/member/`;

	const changeHandle: InputOnChangeFormItem = ({ formId, field, value }) => {
		const newInputProps = inputProps.map((form) =>
			form.id == formId
				? {
						id: form.id,
						form: form.form.map((item) =>
							field === item.field ? { ...item, value, invalid: false } : item,
						),
				  }
				: form,
		);

		setInputProps(newInputProps);
	};

	const sendForm = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();

		setIsLoading(true); // @ts-ignore
		const values = getValueFromArray(inputProps);
		const options = {
			method: "POST",
			data: { type: "extract", ...values },
		};

		request(url, options).then((res) => {
			const { data, status } = res;
			console.log("here", status, data);
			if (status >= 200 && status < 300) {
				setTimeout(() => {
					navigate(`/applications/${data?.common?.id || data?.id}`);
				}, 250);
			} else {
				const { errors } = data;
				if (errors && errors.length > 0) {
					if (errors[0].field === "non_field_errors") {
						enqueueSnackbar("При отправке запроса возникла ошибка!", {
							variant: "error",
							description: errors[0] ? errors[0].message : "",
							autoHideDuration: null,
						});
					} else {
						// setInputProps(addedErrorOnField(errors, inputProps));
					}
				}
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		});
	};

	const addForm = () => {
		const id = (inputProps.at(-1)?.id || 1) + 1;
		console.log("id", inputProps.at(-1)?.id, id);
		setInputProps([
			...inputProps,
			{
				id,
				form: extractInputs,
			},
		]);
	};

	const removeForm = (formId: number) => {
		setInputProps(inputProps.filter((form) => form.id !== formId));
	};

	return (
		<Container className='small'>
			<Typography component='h2' variant='h2' id='modal-title'>
				Заявка на получение выписки из реестра членов СРО
			</Typography>

			<CustomForm component='form' onSubmit={(e) => sendForm(e)}>
				<FormsWrapper>
					{inputProps.map((form, index) => (
						<FormControlItem key={form.id}>
							<ExtractContent>
								{form.form.map(({ id, ...other }) => (
									<InputsSpreader
										key={id}
										id={id}
										{...other}
										onChange={(value) =>
											changeHandle({ formId: form.id, ...value })
										}
									/>
								))}
							</ExtractContent>
							{index !== 0 && (
								<RemoveFormButtom onClick={() => removeForm(form.id)}>
									<TrashcanIcon />
									<Typography component='span'>Удалить сотрудника</Typography>
								</RemoveFormButtom>
							)}
						</FormControlItem>
					))}
				</FormsWrapper>
				<ButtonContainer>
					<Button
						type='submit'
						className='extract-btn'
						preloader={{ loading: isLoading }}
						disabled={isLoading}>
						Создать
					</Button>
					<Button variant='outlined' onClick={addForm}>
						Добавить сотрудника
					</Button>
				</ButtonContainer>
			</CustomForm>
		</Container>
	);
};

export default CreationExtract;
