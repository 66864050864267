import { rgba } from "styles/mixins";
import { styled } from "@mui/material/styles";
import {
	Box,
	Accordion as AccordionMui,
	AccordionSummary as AccordionSummaryMui,
	AccordionDetails as AccordionDetailsMui,
	RadioGroup,
	FormControlLabel,
	Radio as MuiRadio,
	FormControl as FormControlMui,
	Button,
} from "@mui/material";
import { ButtonContainer as ButtonContainerParent } from "components/Form/styles";
import { grey, line, blue, black, red, liteGrey } from "styles/colors";
import { robotoRegular, robotoMedium } from "styles/fonts";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 680px;
	position: relative;

	&.small {
		width: 770px;
	}
`;

export const CustomForm = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 30px;

	.extract-btn {
		width: 143px;
	}
`;

export const ApplicationsTypes = styled(RadioGroup)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const Accordion = styled(AccordionMui)`
	box-shadow: none;
	position: relative;
	width: 100%;

	&::before {
		content: none;
	}

	&.Mui-expanded {
		margin: 0;
	}
`;

export const AccordionSummary = styled(AccordionSummaryMui)`
	padding: 0;
	position: static;

	.MuiAccordionSummary-content {
		margin: 0;

		&.Mui-expanded {
			margin: 0;
		}
	}
`;

export const AccordionDetails = styled(AccordionDetailsMui)`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 20px 20px;
	position: relative;
	z-index: 10;
`;

export const ApplicationItem = styled(FormControlLabel)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 40px 20px 20px;
	border-radius: 10px;
	text-align: left;
	margin: 0;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${grey};
`;

export const Description = styled(Text)`
	margin-top: 8px;
`;

export const FormControl = styled(FormControlMui)`
	max-height: calc(100vh - 300px);
	overflow-y: scroll;
	padding-right: 4px;

	&::-webkit-scrollbar {
		width: 6px;
		right: 2px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${rgba(liteGrey, 0.5)};
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${liteGrey};
	}
`;

export const Radio = styled(MuiRadio)`
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	border: 1px solid ${line};
	transition: all ease-in-out 0.25s;

	&:hover {
		background: ${rgba(grey, 0.03)};
	}

	&.Mui-checked {
		border: 1px solid ${blue};

		&:hover {
			background: transparent;
		}
	}
`;

export const RadioLabel = styled(Box)`
	&.description {
		display: grid;
		grid-template-columns: 140px auto;
		gap: 8px;
	}
`;

export const Label = styled(Box)`
	font-size: 15px;
	line-height: 22px;
	color: ${black};
	margin-bottom: 8px;

	&::after {
		content: "*";
		display: "inline-block";
		color: ${red};
		margin-left: 3px;
	}
`;

export const ExtractContent = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;

export const PopupContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	gap: 30px;
	width: 700px;

	form {
		width: 100%;
	}
`;

export const AdditionalForm = styled(Box)`
	width: 100%;
	position: relative;
`;

export const ButtonContainer = styled(ButtonContainerParent)`
	display: flex;
	gap: 8px;
	padding-top: 8px;
`;

export const FormControlItem = styled(FormControlMui)`
	border: 1px solid ${line};
	padding: 24px;
	border-radius: 10px;
`;

export const FormsWrapper = styled(Box)`
	max-height: calc(100vh - 300px);
	overflow-y: scroll;
	padding-right: 4px;
	display: flex;
	flex-direction: column;
	gap: 30px;

	&::-webkit-scrollbar {
		width: 6px;
		right: 2px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${rgba(liteGrey, 0.5)};
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${liteGrey};
	}
`;

export const RemoveFormButtom = styled(Button)`
	display: flex;
	align-items: center;
	gap: 7px;
	position: relative;
	padding: 0 !important;
	margin-right: auto;
	margin-top: 40px;

	&:hover {
		background: transparent;
		&::before {
			background: ${rgba(red, 0.1)};
		}
	}

	&::before {
		content: "";
		display: block;
		width: calc(100% + 24px);
		height: calc(100% + 16px);
		border-radius: 6px;
		position: absolute;
		transform: translate(-2px, 0);
		background: rgba(243, 246, 250, 0);
		z-index: 0;
		transition: all 0.25s ease-in-out;
	}

	span,
	svg {
		position: relative;
		z-index: 1;
	}

	span {
		font-family: ${robotoMedium};
		font-size: 15px;
		line-height: 130%;
		color: ${red};
		text-decoration: none;
	}

	svg {
		stroke: ${red};
	}
`;
