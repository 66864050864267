import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const PopupContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 24px;
	width: 400px;
`;

export const FormWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	height: 100%;
`;
