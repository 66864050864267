import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { profileMenu, detailMenu, adminMenu } from "assets/data";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import { useUser, useUserDetail, setUserDetail } from "utils/store";
import { UserPageProps, UserItemProfileProps } from "utils/types";
import { UserItem } from "components/UserItem";
import { SideMenu } from "components/SideMenu";
import { WhiteLayer } from "styles/common";
import { Container, Wrapper, Content } from "./styles";

export const UserProfile = ({ detail, admin }: UserPageProps) => {
	const [commonPath, setCommonPath] = useState("");
	const [userId, setUserId] = useState<string | number | undefined>();
	const [userData, setUserData] = useState<UserItemProfileProps>({
		fullName: "",
		status: { type: "active", name: "" },
		id: 1,
		registrationDate: "",
		avatar: null,
		color: "",
		role: [],
	});
	const [isLoading, setIsLoading] = useState(true);
	const [backUrl, setBackUrl] = useState<string | undefined>();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const user = useUser();
	const userDetail = useUserDetail();
	const request = useRequest;

	// useEffect(() => {
	// 	if (detail && !userDetail && userId) {
	// 		getUser(userId);
	// 		console.log("userId", userId);
	// 	}
	// }, [userId]);

	useLayoutEffect(() => {
		const splitedPath = pathname.split("/");
		const newUserId = detail
			? splitedPath.length === 3
				? splitedPath.at(-1)
				: splitedPath.at(-2)
			: user?.id;
		if (userId != newUserId && userDetail?.id != newUserId) {
			setUserId(newUserId);
			if (newUserId) getUser(newUserId);
		}
	}, [pathname]);

	useLayoutEffect(() => {
		if (detail) {
			if (userDetail) {
				const { fullName, status, id, registrationDate, avatar, role, color } =
					userDetail;
				setUserData({
					fullName,
					status: status ? status : { type: "active", name: "" },
					id,
					registrationDate: registrationDate ? registrationDate : "",
					avatar,
					role,
					color,
				});
			}
		} else {
			if (user) {
				const { fullName, status, id, registrationDate, avatar, role, color } =
					user;
				setUserData({
					fullName,
					status: status ? status : { type: "active", name: "" },
					id,
					registrationDate: registrationDate ? registrationDate : "",
					avatar,
					role,
					color,
				});
			}
		}
	}, [user, userDetail, detail]);

	useLayoutEffect(() => {
		const splitedPath = pathname.split("/");
		if (detail) setBackUrl(`/${splitedPath.at(1)}`);
		setCommonPath(
			detail
				? splitedPath.length === 3
					? pathname
					: `/${splitedPath[1]}/${splitedPath[2]}`
				: splitedPath.length === 2
				? pathname
				: `/${splitedPath[1]}`,
		);
	}, [pathname, detail]);

	const getUser = (id: string | number) => {
		// request(`${baseApiUrl}/users/${id}/`).then(({ data }) => {
		// 	if (data) {
		// 		dispatch(setUserDetail(data));
		// 	}
		// 	setIsLoading(false);
		// });
	};

	return (
		<Wrapper>
			<UserItem
				id={userData.id}
				registrationDate={userData.registrationDate}
				fullName={userData.fullName}
				status={userData.status}
				avatar={userData.avatar}
				role={userData.role}
				color={userData.color}
				uploader={true}
				backUrl={backUrl}
				showRole={false}
			/>
			<Content>
				<Container>
					<WhiteLayer>
						<Outlet />
					</WhiteLayer>
				</Container>
				<SideMenu
					list={detail ? (admin ? adminMenu : detailMenu) : profileMenu}
					commonPath={commonPath}
				/>
			</Content>
		</Wrapper>
	);
};

export default UserProfile;
