import { SwitchTransition, CSSTransition } from "react-transition-group";
import { CircularProgress } from "@mui/material";
import { Container, Content, Wrapper } from "./styles";

interface LoaderProps {
	active: boolean;
	children?: JSX.Element;
	width?: string;
	height?: string;
}

export const Preloader = ({ active, children, width, height }: LoaderProps) => {
	return (
		<Wrapper width={width} height={height}>
			<SwitchTransition mode='out-in'>
				<CSSTransition
					key={active ? "preloader" : "content"}
					timeout={250}
					classNames='fadeIn'>
					{active ? (
						<Container className={active ? "active" : ""}>
							<CircularProgress />
						</Container>
					) : (
						<Content style={{ visibility: active ? "hidden" : "visible" }}>
							{children && children}
						</Content>
					)}
				</CSSTransition>
			</SwitchTransition>
		</Wrapper>
	);
};
