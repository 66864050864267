import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import { ErrorResult } from "components/ErrorResult";
import { adminRoleId } from "utils/constants";
import { getTokensFromStorage } from "utils/helpers";
import { useUser, setUser } from "utils/store";
import { Redirect } from "./Redirect";

interface Props {
	children: JSX.Element;
	forAdmin?: boolean;
}

export const PrivateRoute = ({ children, forAdmin }: Props) => {
	const dispath = useDispatch();
	const user = useUser();
	const token = getTokensFromStorage();

	if (!user && !token) {
		dispath(setUser(null));
		return <Redirect link='/login' />;
	}

	const isAdmin = user?.role.find(({ id }) => id === adminRoleId) !== undefined;

	return forAdmin ? (
		<SwitchTransition mode='out-in'>
			<CSSTransition
				key={isAdmin ? "admin" : "user"}
				timeout={300}
				classNames='fade'>
				{isAdmin ? children : <ErrorResult code={404} url='/' />}
			</CSSTransition>
		</SwitchTransition>
	) : (
		children
	);
};

export default PrivateRoute;
