import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { robotoMedium } from "styles/fonts";
import { blue } from "styles/colors";

export const Content = styled(Box)`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 16px;
	width: 100%;
`;

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const DownloadAll = styled(Button)`
	display: flex;
	align-items: center;
	gap: 7px;
	position: relative;
	padding: 0 !important;

	&:hover {
		&::before {
			background: rgba(243, 246, 250, 1);
		}
	}

	&::before {
		content: "";
		display: block;
		width: calc(100% + 24px);
		height: calc(100% + 16px);
		border-radius: 6px;
		position: absolute;
		transform: translate(-2px, 0);
		background: rgba(243, 246, 250, 0);
		z-index: 0;
		transition: all 0.25s ease-in-out;
	}

	span,
	svg {
		position: relative;
		z-index: 1;
	}

	span {
		font-family: ${robotoMedium};
		font-size: 15px;
		line-height: 130%;
		color: ${blue};
		text-decoration: none;
	}

	svg {
		stroke: ${blue};
	}
`;

export const FilesList = styled(Box)`
	width: 100%;
	position: relative;
`;
