import { useUser } from "utils/store/hooks";
import { Header } from "components/Header";
import { NavMenu } from "components/NavMenu";
import { Footer } from "components/Footer";
import { Logotype } from "components/Logotype";
import { getTokensFromStorage } from "utils/helpers";
import { Navigation } from "utils/router/Navigation";
import { layoutStyles } from "./styles";

const { Container, Wrapper, HeaderAuth, Content } = layoutStyles;

export const Layout = () => {
	const isAuth = useUser();
	const token = getTokensFromStorage();

	return (
		<Wrapper auth={isAuth !== null}>
			{isAuth && <NavMenu />}
			<Container>
				{isAuth ? (
					<Header />
				) : (
					<HeaderAuth>
						<Logotype />
					</HeaderAuth>
				)}
				<Content auth={!isAuth && !token}>
					<Navigation />
				</Content>
				{!isAuth && <Footer />}
			</Container>
		</Wrapper>
	);
};
