import { useState, useEffect } from "react";
import { InputOnChange } from "utils/types";
import { LoupeIcon } from "assets/icons";
import { useSearchParams } from "react-router-dom";
import { Input } from "components/FormElements";
import { useDebounce } from "utils/hooks";
import { Wrapper } from "./styles";

interface SearchProps {
	onChange?: (value: string) => void;
	placeholder?: string;
	withParam?: boolean;
}

export const Search = ({
	onChange,
	placeholder = "Введите номер или название заявки",
	withParam,
}: SearchProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const filterValue = searchParams.get("search");
	const [value, setValue] = useState(withParam ? filterValue || "" : "");
	const valueDebounce = useDebounce(value, 600);

	useEffect(() => {
		if (onChange) onChange(valueDebounce);
		if (withParam) {
			const currentParams = Object.fromEntries(
				// @ts-ignore
				[...searchParams].filter((item) => item[0] !== "page"),
			);
			setSearchParams({ ...currentParams, search: String(valueDebounce) });
		}
	}, [valueDebounce]);

	const changeValue: InputOnChange = ({ value }) => {
		setValue(`${value}`);
	};

	return (
		<Wrapper>
			<Input
				id={1}
				type='text'
				field='search'
				className={withParam ? "search" : ""}
				placeholder={placeholder}
				value={value}
				onChange={changeValue}
				startAdornment={<LoupeIcon className='adornedStart' />}
			/>
		</Wrapper>
	);
};
