import { FormSectionDataProps } from "utils/types";
export const employeeInfo: FormSectionDataProps[] = [
	{
		id: "applicant",
		title: "Данные сотрудника",
		inputs: [
			{
				id: 81,
				value: "",
				field: "fullName",
				label: "Ф.И.О",
				required: true,
			},
			{
				id: 82,
				value: "",
				field: "phone",
				label: "Телефон",
				mask: "+7 999 999 99 99",
				required: true,
			},
			{
				id: 83,
				value: "",
				field: "email",
				type: "email",
				label: "Почта",
				required: true,
			},
			{
				id: 84,
				value: "",
				field: "position",
				label: "Должность",
			},
			{
				id: 85,
				value: "",
				field: "department",
				label: "Отдел",
			},
		],
	},
	{
		id: "roles",
		title: "Роль",
		inputs: [
			{
				id: 31,
				value: [],
				field: "roles",
				type: "select",
				multiple: true, // @ts-ignore
				replaceName: true,
				placeholder: "Выберете роль",
				options: [],
				required: true,
			},
		],
	},
	{
		id: "contactPerson",
		title: "Данные руководителя",
		inputs: [
			{
				id: 91,
				value: "",
				field: "fullName",
				label: "Ф.И.О",
			},
			{
				id: 95,
				value: "",
				field: "mobilePhone",
				label: "Телефон",
				mask: "+7 999 999 99 99",
			},
		],
	},
];
