import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Typography } from "@mui/material";
import { SelectOptionProps } from "utils/types";
import { useRequest } from "utils/hooks";
import { baseApiUrl } from "utils/constants";
import { Button, Select } from "components/FormElements";
import { Popup } from "components/Popup";
import { Preloader } from "components/Preloader";
import { PopupContainer, FormWrapper } from "./styles";

interface TerminationPopupProps {
	afterClose: (value: boolean) => void;
	show: boolean;
	reason: string;
	setReason: Dispatch<SetStateAction<{ value: string; name: string } | null>>;
	onSubmit: () => void;
}

export const TerminationPopup = ({
	afterClose,
	show,

	reason,
	setReason,
	onSubmit,
}: TerminationPopupProps) => {
	const request = useRequest;
	const [isPreloading, setIsPreloading] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [reasons, setReasons] = useState<SelectOptionProps[]>();

	useEffect(() => {
		request(`${baseApiUrl}/applications/reasons/`)
			.then((res) => {
				const { data, status } = res;

				if (status >= 200 && status < 300) {
					console.log(data);
					setReasons(
						// @ts-ignore
						data.map(({ name, label }) => ({
							value: name,
							name: label,
							id: name,
						})),
					);
				}
				setTimeout(() => {
					setIsPreloading(false);
				}, 250);
			})
			.catch(() => {
				setTimeout(() => {
					setIsPreloading(false);
				}, 250);
			});
	}, []);

	useEffect(() => {
		if (!show) {
			setIsLoading(false);
		}
	}, [show]);

	return (
		<Popup active={show} afterClose={afterClose}>
			<PopupContainer
				component='form'
				onSubmit={(e) => {
					e.preventDefault();
					setIsLoading(true);
					onSubmit();
				}}>
				<Typography component='h2' variant='h2' id='modal-title'>
					Укажите основание причины нарушения
				</Typography>
				<Preloader active={isPreloading} height='124px' width='100%'>
					<FormWrapper>
						{reasons && (
							<Select
								id={2}
								field=''
								value={reason}
								placeholder='Выберете основание'
								options={reasons}
								required
								replaceName
								onChange={({ value }) => setReason(JSON.parse(`${value}`))}
							/>
						)}
						<Button
							type='submit'
							preloader={{ loading: isLoading }}
							disabled={isLoading}>
							Принять
						</Button>
					</FormWrapper>
				</Preloader>
			</PopupContainer>
		</Popup>
	);
};

export default TerminationPopup;
