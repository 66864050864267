import { useEffect, useState } from "react";
import { useRequest } from "utils/hooks";
import { RadioGroup, InputsSpreader } from "components/FormElements";
import {
	InputDataProps,
	InputOnChange,
	NotificationAction,
	CheckboxListTypes,
} from "utils/types";
import { Conteiner, Label, RadioList } from "./styles";

interface PermissionsCreateOrDeleteProps {
	permission: NotificationAction;
	fieldType: string;
	disabled: boolean;
	setOptions: (val: any) => void;
}

export const PermissionsCreateOrDelete = ({
	permission,
	fieldType,
	disabled,
	setOptions,
}: PermissionsCreateOrDeleteProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const request = useRequest;
	const [inputProps, setInputProps] = useState<InputDataProps[]>();
	const [listProps, setListProps] = useState<CheckboxListTypes>();
	const [defaultValue, setDefaultValue] = useState<string>();

	useEffect(() => {
		if (permission.options) {
			if (fieldType === "radio") {
				setListProps(
					// @ts-ignore
					permission.options.map(({ codeName, name, checked, id }) => {
						if (checked) setDefaultValue(String(id ? id : codeName));
						return {
							name,
							label: name,
							id: id ? id : codeName,
							checked,
							value: codeName,
						};
					}),
				);
			} else {
				setInputProps(
					// @ts-ignore
					permission.options.map(
						({ codeName, name, checked, value, id }, index) => ({
							id: id || codeName || index,
							type: checked !== undefined ? "checkbox" : undefined,
							value: value !== undefined ? value : checked,
							label: name,
							field: codeName || String(id),
						}),
					),
				);
			}
		}
	}, [permission]);

	const changeValue: InputOnChange = ({ field, value }) => {
		//@ts-ignore
		const newOptions = permission.options.map((item) =>
			field === String(item.id ? item.id : item.codeName)
				? typeof value === "boolean"
					? { ...item, checked: value }
					: { ...item, value }
				: item,
		);

		setOptions({ ...permission, options: newOptions });
	};

	return (
		<Conteiner>
			<Label noCheck={disabled}>{permission.name}</Label>

			{listProps && fieldType === "radio" && (
				<RadioList>
					<RadioGroup
						id={1}
						list={listProps}
						field={permission.codeName}
						onChange={changeValue}
						disabled={disabled}
						defaultValue={defaultValue}
					/>
				</RadioList>
			)}
			{inputProps &&
				inputProps.map(({ id, type, field, value, label }) => (
					<InputsSpreader
						key={id}
						id={id}
						value={value}
						field={field}
						type={type}
						label={label}
						onChange={changeValue}
						disabled={disabled}
					/>
				))}
		</Conteiner>
	);
};
